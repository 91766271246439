import React from 'react';
import tw from 'twin.macro';
import { login, isAuthenticated } from '../../utils/auth';
import Layout from '../../components/UI/Layout';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Card from '../../components/UI/Card';

const Tutorials = () => {
  const {
    dashboard,
    strapiTutorials: { nodes: tutorials },
  } = useStaticQuery(query);

  if (!isAuthenticated()) {
    login();
    return <p>Redirecting to login...</p>;
  }

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d?.getMonth() + 1}/${d?.getDate()}/${d?.getFullYear()}`;
  };

  return (
    <Layout>
      <div
        css={[tw`px-4 flex-col mt-8 max-w-5xl md:px-16 mx-auto items-center`]}
      >
        <div css={[tw`mb-6`]}>
          <h1 css={['font-family: "LatoBold";', tw`text-2xl`]}>
            {dashboard.Title}
          </h1>
          <div
            css={[tw`text-sm text-gray-500 leading-4`]}
            dangerouslySetInnerHTML={{ __html: dashboard.Description }}
          />
        </div>
        <div css={[tw`flex flex-col space-y-8`]}>
          {tutorials.map((tutorial) => (
            <Card key={tutorial.id} styles={[tw`px-4 md:px-12`]}>
              <div>
                <div
                  className="markdown-body"
                  css={[tutorial.Content && tw`mb-4`]}
                >
                  <h1>{tutorial.Title}</h1>
                  <p
                    css={[
                      'display: -webkit-box; overflow: hidden; -webkit-line-clamp: 5; -webkit-box-orient: vertical;',
                    ]}
                  >
                    {tutorial.Description}
                  </p>
                </div>
                <div css={[tw`flex flex-row justify-between`]}>
                  <Link
                    to={tutorial.tutorialPath}
                    css={[tw`text-blue-600`, 'font-family: "LatoBold";']}
                  >
                    See more...
                  </Link>
                  <p css={[tw`text-gray-400 text-sm`]}>
                    Published on {formatDate(tutorial.published_at)}
                  </p>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Tutorials;

const query = graphql`
  {
    strapiTutorials: allStrapiTutorials {
      nodes {
        id
        Title
        Tags
        Description
        Content
        ShowInNavigation
        Slug
        published_at
        tutorialPath: gatsbyPath(filePath: "/tutorials/{strapiTutorials.Slug}")
      }
    }
    dashboard: strapiTutorialsDashboard {
      Title
      Description
    }
  }
`;
